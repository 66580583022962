@import './scss/head';

.mobileGames{
    background: $bg-dark;
    padding-top: 7rem;
    margin-top: 7rem;

    .title{
        text-align: center;
        color: $pink;
        text-align: center;
        font-family: Poppins;
        font-size: 2.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.0225rem;
        margin-bottom: 0;
    }

    .images{
        margin-top: 4rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow-x: auto;

        .image{
            img{
                user-select: none;
            }
        }
    }
}

.top-game{
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.015rem;
    margin-bottom: 2rem;
}

.games {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 5rem;
    grid-row-gap: 2rem;
    overflow-x: auto;
    padding-bottom: 5rem;
    margin-left: 3rem;

    a{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-decoration: none !important;

        img{
            box-shadow: -12px 10px 19px 0px rgba(0, 0, 0, 0.25);
            margin-bottom: 1rem;
            border-radius: 25px;
            overflow: hidden;
            user-select: none;
        }
        
        span {
            color: #fff;
            text-align: center;
            font-family: Poppins;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: 112.688%; /* 1.69031rem */
        }
    }

    @media screen and (min-width: 1400px) {
        a:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
        a:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
        a:nth-child(3) { grid-area: 1 / 3 / 2 / 4; }
        a:nth-child(4) { grid-area: 1 / 4 / 2 / 5; }
        a:nth-child(5) { grid-area: 1 / 5 / 2 / 6; }
        a:nth-child(6) { grid-area: 2 / 1 / 3 / 2; }
        a:nth-child(7) { grid-area: 2 / 2 / 3 / 3; }
        a:nth-child(8) { grid-area: 2 / 3 / 3 / 4; }
        a:nth-child(9) { grid-area: 2 / 4 / 3 / 5; }
        a:nth-child(10) { grid-area: 2 / 5 / 3 / 6; }
        a:nth-child(11) { grid-area: 3 / 2 / 4 / 3; }
        a:nth-child(12) { grid-area: 3 / 3 / 4 / 4; }
        a:nth-child(13) { grid-area: 3 / 4 / 4 / 5; }
    }
}
    
    