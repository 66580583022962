@import '../assets/scss/head';

footer{
    background-color: $navy;
    padding-top: 2rem;

    .row{
        align-items: center;

        .logo{
            width: 100%;
            max-width: 50%;
            margin: 0 auto;
            display: flex;
            justify-content: center ;
            align-items: center;

            img{
                width: 100%;
            }

            @media screen and (min-width: 768px) {
                max-width: 100%;
            }
        }
    }
}

.footer-content{
    // text-align: right;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #FFF;

    @media screen and (min-width: 768px) {
        text-align: right;
        align-items: flex-end;
    }

    h6{
        text-align: inherit;
        font-family: Poppins;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.015rem;
    }
    
    p{
        color: inherit;
        text-align: inherit;
        font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.0125rem;
    }

    a{
        text-decoration: none !important;
        color: $pink;
        text-align: center;
        font-family: Poppins;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.015rem;
    }
}