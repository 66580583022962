@import './scss/head';

.video-container{
    // width: 80%;
    margin: 0 auto;

    @media screen and (min-width: 568px) {
        width: 80%;
    }

    .video-area{
        position: relative;
        width: 100%;
        padding-top: calc(9 / 16 * 100%);
    }

    iframe{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.media-section{
    background: $bg-dark;
    color: #fff;

    h3{
        margin-bottom: 2.5rem;
    }
}

.images-area{
    display: grid;
    // grid-template-columns: 1fr 1fr;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin: 5rem 0 7rem 0;

    @media screen and (min-width: 568px) {
        grid-template-columns: 1fr 1fr;
    }

    .image{
        width: 100%;
        padding-top: calc(9 / 16 * 100%);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}