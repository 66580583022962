@import './scss/head';

.section-head{
    background-color: $navy;
    color: $pink;
    margin-top: 2.75rem;
    padding: 1.5rem 0;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.015rem;
    margin-bottom: 4.25rem;
}

.aboutus-section{
    h4{
        color: #000931;
        font-family: Poppins;
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.02rem;
    }

    p{
        color: #000;
        font-family: Noto Sans;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.275rem;
        letter-spacing: -0.0125rem;
    }
}

.linkedIn-section{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    padding: 0 2rem;
    flex-direction: column;

    img{
        margin-bottom: 3rem;
    }
}

.linkedin-button{
    background-color: $linkedin-blue;
    color: #fff;
    padding: 1.25rem 0;
    display: block;
    text-align: center;
    text-decoration: none !important;
    border: 3px solid $text-color;
    margin-bottom: 1.5rem;
    transform: translateY(0);
    transition: .2s all ease-in-out;
    box-shadow: 0px 2px 0px 0px $linkedin-blue;
    
    &:hover{
        transform: translateY(-5px);
        box-shadow: 0px 6px 0px 0px $linkedin-blue;
    }
}

.job-title{
    color: $linkedin-blue;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.01rem;
}

.mail-section{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 8rem;
    gap: 1.3rem;
    margin-bottom: 2rem;

    @media screen and (min-width: 568px) {
        flex-direction: row;
        gap: 3rem;
    }

    img{
        max-width: 50px;
    }

    a{
        width: 25rem;
        text-align: center;
        display: block;
        text-decoration: none;
        border: 3px solid $text-color;
        padding: 1.25rem 0;

        &:nth-child(1){
            background-color: $pink;
            color: $navy;
            // margin-right: 3rem;
        }

        &:nth-child(3){
            background-color: $navy;
            color: $pink;
            // margin-left: 3rem;
        }
    }
}