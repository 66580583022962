@import './scss/head';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    color: $text-color;
    font-weight: 600;
    font-size: 10px;

    @media screen and (min-width: 568px) {
        font-size: 12px;
    }

    @media screen and (min-width: 992px) {
        font-size: 14px;
    }

    @media screen and (min-width: 1400px) {
        font-size: 16px;
    }
}

.rogue-logo{
    max-width: 100%;
}

.text-bold{
    font-weight: 800;
}

.navbar-brand {
    img {
        max-width: 3.5rem;
        object-fit: cover;
    }
}

.navbar-pink {
    background-color: $pink;
}

.nav-link{
    color: $text-color;
    margin-right: 1.5rem;
}

.right-navbar{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

.social-buttons{
    @extend .nav-link !optional;
    text-decoration: none !important;

    &:not(:last-child){
        margin-right: 2.5rem;
    }
}