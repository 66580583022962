@import './scss/head';

.rouge-ottoman-tower {
    background-image: url('./images/rouge-ottoman-tower.png');
    background-position: center center;
    height: 620px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.socials {
    background: $navy;
    padding: 2rem 0;
    margin-bottom: 2.5rem;

    .container {
        color: #fff;

        h3 {
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.015rem;
            margin-bottom: 2rem;
        }

        .social-medias {
            display: grid;
            // grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-columns: 1fr;
            // gap: 5rem;
            gap: 1rem;

            @media screen and (min-width: 768px) {
                grid-template-columns: 1fr 1fr 1fr 1fr;
                gap: 5rem;
            }

            .social-media-button {
                display: inline-block;
                width: 100%;
                padding: 16px;
                text-align: center;
                font-family: Poppins;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.015rem;
                text-decoration: none !important;
                color: #fff;
                border: 3px solid #181721;
                transition: .2s all ease-in-out;

                &:hover{
                    transform: scale(1.1);
                }
            }
        }
    }
}

.rogue-ottoman-discord {
    background-color: #e5ebea;

    .container, .container-fluid {
        // padding: 2rem 4rem 4rem;

        @media screen and (min-width: 426px) {
            padding: 2rem 4rem 4rem;
        }
    }

    .image-area {
        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .content-area {
        .wishlist{
            justify-content: start;
        }
        
        h4 {
            color: $navy;
            font-family: Poppins;
            font-size: 2rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.02rem;
            margin-bottom: 1rem;
        }

        p {
            color: #000;
            font-family: Noto Sans;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: 182%; /* 2.275rem */
            letter-spacing: -0.0125rem;
            margin-bottom: 2rem;

            span {
                color: #037a0d;
                font-family: Noto Sans;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: 182%;
                letter-spacing: -0.0125rem;
            }
        }

        .red-link {
            color: $red;
            font-family: Poppins;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.015rem;
            text-decoration-line: underline;
            display: block;
        }

        .discord-btn {
            width: 100%; //
            margin-top: 5rem;
            display: inline-block;
            text-decoration: none !important;
            border: 3px solid #181721;
            background: #5865f2;
            box-shadow: 0px 2px 0px 0px #5865f2;
            color: #fff;
            font-family: Poppins;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.0125rem;
            padding: 1rem 1.7rem;
            transition: .2s all ease-in-out;

            @media screen and (min-width: 768px) {
                width: auto;
            }

            &:hover{
                transform: translateY(-5px);
                box-shadow: 0px 6px 0px 0px #5865f2;
            }

            img {
                margin-right: 1.4rem;
            }
        }
    }
}
