@import './scss/head';

.wishlist{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}

.wishlist-btn{
    width: 100%;
    max-width: 311px;
    border: 1px solid #000;
    padding: .5rem .75rem;
    background: #FFF;
    box-shadow: 0px 0px 0px 0px rgba($color: #000000, $alpha: .3);
    transition: .2s all ease-in-out;

    img{
        width: 100%;
        object-fit: cover;
    }

    &:hover{
        transform: scale(1.05);
        box-shadow: 0px 0px 24px 0px rgba($color: #000000, $alpha: .3);
    }
}